import React from "react";
import { motion } from "framer-motion";

export default function BrandsStyle2({ data }) {
  return (
    <div className="cs_brands_2_wrap">
      <div className="cs_brands cs_style_2">
        {data?.map((item, index) => (
          <motion.div
            className="cs_brand"
            key={index}
            initial="offscreen"
            whileInView="onscreen"
            variants={{
              offscreen: {
                opacity: 0,
              },
              onscreen: {
                opacity: 1,
                transition: {
                  duration: 0.20,
                  delay: index / 16,
                },
              },
            }}
            viewport={{
              once: true,
              amount: 0.2,
            }}
          >
            {item.href || item.href !== '#' ? (
              <a href={item.href} target="blank">
                <img src={item.logoSrc} alt={item.logoAlt} />
              </a>
            ) : (
              <img src={item.logoSrc} alt={item.logoAlt} />
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
}
