import React from "react";
import WaterWave from "react-water-wave";
import Spacing from "../Spacing";
import parse from "html-react-parser";

export default function VideoModal({ videoSrc, bgUrl, title, titleVariant }) {
  return (
    <>
      {title ? (
        <>
          <h2
            className={`cs_video_block_1_title cs_fs_68 text-center mb-0 ${
              titleVariant ? titleVariant : "text-uppercase"
            }`}
          >
            {parse(title)}
          </h2>
          <Spacing lg="80" md="45" />
        </>
      ) : (
        ""
      )}
   
        <WaterWave
          className="cs_video_block cs_style_1 cs_bg_filed position-relative d-flex justify-content-center align-items-center cs_ripple_activate overflow-hidden"
          imageUrl={bgUrl}
        >
          {({ dropRef }) => (
            <div ref={dropRef} style={{ width: "100%", height: "100%" }} />
          )}
        </WaterWave>

    </>
  );
}
