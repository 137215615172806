import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Header({
  logoUrl,
  colorVariant,
  cart,
  actionBtnText,
  actionBtnUrl,
}) {
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`cs_site_header cs_style_1 cs_sticky_header ${
        colorVariant ? colorVariant : "cs_primary_color"
      } ${isSticky ? "cs_gescout_show" : ""}`}
    >
      <div className="cs_main_header">
        <div className="container">
          <div className="cs_main_header_in">
            <div className="cs_main_header_left">
              <Link className="cs_site_branding" to="/">
                <img src={logoUrl} alt="Logo" />
              </Link>
            </div>
            <div className="cs_main_header_center">
              <div className="cs_nav cs_medium cs_primary_font">
                <ul
                  className={`${
                    mobileToggle ? "cs_nav_list cs_active" : "cs_nav_list"
                  }`}
                >
                  <li to="home">
                    <NavLink
                      to=""
                      attr="Home"
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <HashLink
                      smooth
                      to="/#services"
                      attr="Services"
                      onClick={() => setMobileToggle(!mobileToggle)}
                      scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })}
                    >
                      Services
                    </HashLink>
                  </li>


                  <li to="portfolio">
                    <NavLink
                      to="portfolio"
                      attr="Portfolio"
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      Portfolio
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="our-team"
                      attr="Our Team"
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      Our Team
                    </NavLink>
                  </li>

                  <li>
                    <HashLink
                      smooth
                      to="/#our_partners"
                      attr="Our Partners"
                      onClick={() => setMobileToggle(!mobileToggle)}
                      scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })}
                    >
                      Our Partners
                    </HashLink>
                  </li>

                  <li>
                    <NavLink
                      to="contact"
                      attr="Contact"
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>

                <span
                  className={
                    mobileToggle
                      ? "cs_menu_toggle cs_teggle_active"
                      : "cs_menu_toggle"
                  }
                  onClick={() => setMobileToggle(!mobileToggle)}
                >
                  <span></span>
                </span>
              </div>
            </div>

            {location.pathname !== "/contact" && (
              <div className="cs_main_header_right">
                <Link
                  to={actionBtnUrl}
                  className={`cs_btn cs_style_1  ${
                    colorVariant ? "cs_btn_white" : ""
                  }`}
                >
                  {actionBtnText}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
