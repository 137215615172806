import React from "react";

export default function TeamMember({
  memberImg,
  memberName,
  memberTitle,
  linkedin,
}) {
  return (
    <div className="team-card" tabIndex="-1">
      <div className="team-card-description">
        <div className="first_row">
          <h3 className="team-name">{memberName}</h3>
          <a href={linkedin} target="_blank" rel="noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="40"
              height="40"
              viewBox="0,0,256,256"
              style={{ fill: "#eeeeee" }}
            >
              <g
                style={{
                  fill: "#ffffff",
                  mixBlendMode: "normal",
                }}
              >
                <g transform="scale(8.53333,8.53333)">
                  <path d="M24,4h-18c-1.105,0 -2,0.895 -2,2v18c0,1.105 0.895,2 2,2h18c1.105,0 2,-0.895 2,-2v-18c0,-1.105 -0.895,-2 -2,-2zM10.954,22h-2.95v-9.492h2.95zM9.449,11.151c-0.951,0 -1.72,-0.771 -1.72,-1.72c0,-0.949 0.77,-1.719 1.72,-1.719c0.948,0 1.719,0.771 1.719,1.719c0,0.949 -0.771,1.72 -1.719,1.72zM22.004,22h-2.948v-4.616c0,-1.101 -0.02,-2.517 -1.533,-2.517c-1.535,0 -1.771,1.199 -1.771,2.437v4.696h-2.948v-9.492h2.83v1.297h0.04c0.394,-0.746 1.356,-1.533 2.791,-1.533c2.987,0 3.539,1.966 3.539,4.522z"></path>
                </g>
              </g>
            </svg>
          </a>
        </div>
        <p className="team-occupation">{memberTitle}</p>
      </div>
      <div className="team-card-image">
        <img
          src={memberImg}
          alt="teamImage"
          className="img-style"
          draggable="false"
        />
      </div>
      <div className="team-card-background">
        <svg
          className="mozzo-pattern"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
        >
          <g id="Layer_1-2">
            <polygon
              className="cls-2"
              points="235.29 0 235.29 235.29 176.47 235.29 176.47 117.65 147.06 176.47 88.24 176.47 58.82 117.65 58.82 235.29 0 235.29 0 0 58.82 0 117.65 117.65 176.47 0 235.29 0"
            />
            <path
              className="cls-2"
              d="M235.29,264.71H0v58.82h58.82L0,441.18v58.82h235.29v-58.82h-58.82l58.82-117.65v-58.82ZM117.65,441.18h-58.82l58.82-117.65h58.82l-58.82,117.65Z"
            />
            <path
              className="cls-2"
              d="M382.35,0c-64.99,0-117.65,52.66-117.65,117.65s52.66,117.65,117.65,117.65,117.65-52.66,117.65-117.65S447.34,0,382.35,0ZM382.35,176.47c-32.47,0-58.82-26.35-58.82-58.82s26.35-58.82,58.82-58.82,58.82,26.35,58.82,58.82-26.35,58.82-58.82,58.82Z"
            />
            <path
              className="cls-1"
              d="M382.35,264.71c-64.99,0-117.65,52.66-117.65,117.65s52.66,117.65,117.65,117.65,117.65-52.66,117.65-117.65-52.66-117.65-117.65-117.65ZM382.35,441.18c-32.47,0-58.82-26.35-58.82-58.82s26.35-58.82,58.82-58.82,58.82,26.35,58.82,58.82-26.35,58.82-58.82,58.82Z"
            />
          </g>
        </svg>
      </div>
    </div>
  );
}
