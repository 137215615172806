import { Icon } from "@iconify/react";
import React, { useState } from "react";
import Turnstile, { useTurnstile } from "react-turnstile";

import Spacing from "../Spacing";

export default function ContactForm({ page }) {
  const turnstileRef = useTurnstile();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [turnstileToken, setTurnstileToken] = useState("");
  const [loading, setLoading] = useState(false); // Optional: To handle loading state
  const [error, setError] = useState(""); // Optional: To handle error messages
  const [success, setSuccess] = useState(""); // Optional: To handle error messages

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await fetch("/send.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          name,
          email,
          phone,
          message,
          "cf-turnstile-response": turnstileToken,
        }),
      });

      const result = await response.json();
      if (result.error) {
        setError(result.error);
      } else {
        setSuccess(result.success);
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setTurnstileToken("");

        // Reset Turnstile
        if (turnstileRef.current) {
          turnstileRef.current.reset();
        }
      }
    } catch (e) {
      setError(
        "Error while processing your request, please contact us directly via email: info@mozzo.ch"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="cs_contact_form_wrap">
      <div className=" cs_contact_form_bg" />
      <form className="cs_contact_form" onSubmit={handleSubmit}>
        <input
          placeholder="Name"
          type="text"
          className="cs_form_field"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="cs_height_20 cs_height_lg_16" />

        <input
          placeholder="E-Mail"
          type="email"
          className="cs_form_field"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <div className="cs_height_20 cs_height_lg_16" />

        <input
          placeholder="Phone Number"
          type="text"
          className="cs_form_field"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <div className="cs_height_20 cs_height_lg_16" />

        <textarea
          cols={30}
          rows={5}
          className="cs_form_field"
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <div className="cs_height_38 cs_height_lg_25" />

        <div className={page === "home" ? "homepage_form" : "contact_form"}>
          {success && <div className="success-message">{success}</div>}
          {error && <div className="error-message">{error}</div>}

          <Turnstile
            sitekey="0x4AAAAAAAaIpddRqbmivyRG"
            onVerify={(token) => setTurnstileToken(token)}
            execution="render"
            ref={turnstileRef}
          />
          <Spacing lg="30" md="30" />
          <button
            className="cs_btn cs_style_1"
            type="submit"
            disabled={loading}
          >
            {loading ? "Sending..." : "Send "}
            <span>
              <i>
                <Icon icon="fa6-solid:arrow-right" />
              </i>
              <i>
                <Icon icon="fa6-solid:arrow-right" />
              </i>
            </span>
          </button>
        </div>
      </form>
    </div>
  );
}
