import React from "react";
import Spacing from "../Spacing";
import SectionHeading from "../SectionHeading";
import { pageTitle } from "../../helpers/PageTitle";
import { motion } from "framer-motion";
import ContactForm from "../ContactForm";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function ContactPage() {
  pageTitle("Contact");
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta
            name="title"
            content="Contact Us - Mozzo Creative Agency"
          ></meta>
          <meta
            name="description"
            content="Let's start something new together. Email: info@mozzo.ch · Phone: +383 44 690 213 · Address: Rr. Deshmoret e Kombit PN, Ferizaj 70000, Republic of Kosovo"
          />
          <meta
            name="keywords"
            content="digital,agency,creative,marketing,web,automation,branding,logo,security,video,contact"
          />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English"></meta>

          <meta
            property="og:title"
            content="Contact Us - Mozzo Creative Agency"
          />
          <meta property="og:site_name" content="Mozzo" />
          <meta property="og:url" content="https://mozzo.ch/contact" />
          <meta
            property="og:description"
            content="Let's start something new together. Email: info@mozzo.ch · Phone: +383 44 690 213 · Address: Rr. Deshmoret e Kombit PN, Ferizaj 70000, Republic of Kosovo"
          />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="/images/ogimage.webp" />
        </Helmet>

        <Spacing lg="70" md="70" />
        <Spacing lg="140" md="80" />
        <SectionHeading title="Let's Talk" subTitle="Contact" shape="shape_6" />
        <Spacing lg="75" md="60" />
        <section>
          <div className="container">
            <div className="row align-items-center cs_gap_y_45">
              <motion.div
                className="col-lg-6"
                initial="offscreen"
                whileInView="onscreen"
                variants={{
                  offscreen: {
                    y: 300,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      bounce: 0.4,
                      duration: 2,
                      stiffness: 70,
                    },
                  },
                }}
                viewport={{
                  once: true,
                  amount: 0.9,
                }}
              >
                <ul className="cs_mp0 cs_contact_info">
                  <li>
                    <p className="cs_fs_21 contact_details">Email:</p>
                    <p className="mb-0">info@mozzo.ch</p>
                  </li>
                  <li>
                    <p className="cs_fs_21 contact_details">Phone:</p>
                    <p className="mb-0">+383 44 690 213</p>
                  </li>
                  <li>
                    <p className="cs_fs_21 contact_details">Address:</p>
                    <p className="mb-0">
                      Rr. Deshmoret e Kombit PN, Ferizaj 70000, Republic of
                      Kosovo
                    </p>
                  </li>
                </ul>
              </motion.div>

              <motion.div
                className="col-lg-6"
                initial="offscreen"
                whileInView="onscreen"
                variants={{
                  offscreen: {
                    y: 300,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      bounce: 0.4,
                      duration: 2,
                      stiffness: 70,
                    },
                  },
                }}
                viewport={{
                  once: true,
                  amount: 0.9,
                }}
              >
                <ContactForm page="contact" />
              </motion.div>
            </div>
          </div>
          <div className="cs_height_0 cs_height_lg_80" />
        </section>
        <Spacing lg="75" md="30" />
        <div className="cs_map">
          <iframe
            id="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11790.418358850713!2d21.1475572!3d42.3722654!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13547f2858d19623%3A0xef7071ae4bd87ca7!2sMOZZO%20MARKETING!5e0!3m2!1sen!2s!4v1713864516619!5m2!1sen!2s"
            allowFullScreen
            title="Mozzo Location"
          />
        </div>
      </HelmetProvider>
    </>
  );
}
