import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Pages/Home";
import PortfolioPage from "./components/Pages/PortfolioPage";
import SinglePortfolio from "./components/Pages/SinglePortfolio";
import TeamPage from "./components/Pages/TeamPage";
import ContactPage from "./components/Pages/ContactPage";
import Layout2 from "./components/Layout/Layout2";
import ErrorPage from "./components/Pages/ErrorPage";
import TopLoadingBar from "./components/TopLoadingBar";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <TopLoadingBar />

    
        <Routes>
          <Route path="/" element={<Layout2 darkMode />}>
            <Route path="portfolio" element={<PortfolioPage />} />
            <Route path="portfolio/:slug" element={<SinglePortfolio />} />
            <Route path="our-team" element={<TeamPage />} />
            <Route path="contact" element={<ContactPage />} />

            <Route path="/" exact index element={<Home />} />

            <Route
              path="privacy-policy"
              exact
              index
              element={<PrivacyPolicy />}
            />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>

    </>
  );
}

export default App;
