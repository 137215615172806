import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";
import ContactForm from "../ContactForm";

const LinksMenuList = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "Services",
    href: "/#services",
  },
  {
    title: "Portfolio",
    href: "/portfolio",
  },
  {
    title: "Our Team",
    href: "/our-team",
  },
  {
    title: "Our Partners",
    href: "/#our_partners",
  },
  {
    title: "Contact",
    href: "/contact",
  },
];

const policyMenuList = [
  {
    title: "Privacy Policy",
    href: "/privacy-policy",
  },
];

const socialBtnList = [
  {
    icon: "fa6-brands:linkedin-in",
    href: "https://www.linkedin.com/company/mozzocreative",
  },
  {
    icon: "fa6-brands:instagram",
    href: "https://instagram.com/mozzocreative",
  },
  {
    icon: "fa6-brands:facebook-f",
    href: "https://facebook.com/mozzocreative",
  },
  {
    icon: "fa6-brands:tiktok",
    href: "https://www.tiktok.com/@mozzocreative",
  },
];

export default function Footer() {
  const location = useLocation();
  const [isFooterFormVisible, setIsFooterFormVisible] = useState(false);

  const handleTheoClick = (e) => {
    e.target.style.transform = "translateY(0)";
    e.target.style.borderWidth = "0px";

    setTimeout(() => {
      e.target.style.opacity = "0";
    }, 500);

    setTimeout(() => {
      setIsFooterFormVisible(!isFooterFormVisible);
    }, 1000);
  };

  return (
    <footer
      className="cs_fooer cs_bg_filed"
      style={{ backgroundColor: "#010101" }}
    >
      <div className="cs_fooer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cs_footer_item">
                <div className="cs_text_widget">
                  <motion.div
                    className="supertext"
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={{
                      offscreen: {
                        y: 150,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          type: "spring",
                          bounce: 0.4,
                          duration: 1.2,
                          stiffness: 50,
                        },
                      },
                    }}
                    viewport={{
                      once: true,
                      amount: 0.4,
                    }}
                  >
                    <span className="lets"> Let's</span>
                    <span className="start"> Start</span>{" "}
                    <span className="something">something</span> <br></br>
                    <span className="new">new </span>
                    <span className="together">together</span>
                    <span className="qudit">!</span>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              {isFooterFormVisible && location.pathname !== "/contact" && (
                <motion.div
                  className="theo_wrapper"
                  initial="offscreen"
                  whileInView="onscreen"
                  variants={{
                    offscreen: {
                      y: 150,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        type: "spring",
                        bounce: 0.4,
                        duration: 1.2,
                        stiffness: 80,
                      },
                    },
                  }}
                  viewport={{
                    once: true,
                    amount: 0.9,
                  }}
                >
                  <ContactForm page="home" />
                </motion.div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 footerthings">
              {location.pathname !== "/contact" && (
                <motion.div
                  className="theo_wrapper"
                  initial="offscreen"
                  whileInView="onscreen"
                  variants={{
                    offscreen: {
                      y: 200,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        type: "spring",
                        bounce: 0.4,
                        duration: 1.2,
                        stiffness: 100,
                      },
                    },
                  }}
                  viewport={{
                    once: true,
                    amount: 0.9,
                  }}
                >
                  <span
                    onClick={(e) => handleTheoClick(e)}
                    style={{
                      display: isFooterFormVisible ? "none" : "flex",
                    }}
                    className="theo"
                  >
                    {" "}
                    Let's Talk
                  </span>
                </motion.div>
              )}

              <motion.div
                className="theo_wrapper"
                initial="offscreen"
                whileInView="onscreen"
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      bounce: 0.4,
                      duration: 0.4,
                      stiffness: 40,
                    },
                  },
                }}
                viewport={{
                  once: true,
                  amount: 0.1,
                }}
              >
                <img src="/images/mozzo_mountain.svg" alt="Mozzo Mountain" />
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="cs_bottom_footer">
          <div className="part_footer part_one_footer">
            <div className="cs_bottom_footer_left">
              <ul className="cs_footer_links cs_mp0">
                {LinksMenuList.map((item, index) =>
                  item.href.includes("#") ? (
                    <li key={index}>
                      <HashLink
                        to={item.href}
                        smooth
                        key={index}
                        attr={item.title}
                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })}
                      >
                        {item.title}
                      </HashLink>
                    </li>
                  ) : (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  )
                )}
              </ul>
            </div>

            <div className="cs_bottom_footer_right">
              <div className="cs_social_btns cs_style_1">
                {socialBtnList.map((item, index) => (
                  <Link
                    to={item.href}
                    className="cs_center"
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                  >
                    <Icon icon={item.icon} />
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <div className="part_footer part_two_footer">
            <div className="cs_bottom_footer_left">
              <p className="cs_copyright">Copyright © 2024 Mozzo Marketing.</p>
            </div>

            <div className="cs_bottom_footer_right">
              <ul className="cs_footer_links cs_mp0">
                {policyMenuList.map((item, index) => (
                  <li key={index}>
                    <Link to={item.href}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
