import React, { useEffect, useState } from "react";
import Spacing from "../Spacing";
import SectionHeading from "../SectionHeading";
import Portfolio from "../Portfolio";
import { pageTitle } from "../../helpers/PageTitle";
import axios from "axios";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const API = "/data/portfolio.json";

export default function PortfolioPage() {
  const [items, setItems] = useState([]);
  useEffect(() => {
    axios
      .get(API)
      .then((res) => {
        setItems(res.data);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  }, []);

  pageTitle("Portfolio");
  return (
    <HelmetProvider>
      <Helmet>
        <meta
          name="title"
          content="Our Portfolio - Mozzo Creative Agency"
        ></meta>
        <meta
          name="description"
          content="A Variety of Significant Projects that Have Been Successfully Delivered"
        />
        <meta
          name="keywords"
          content="digital,agency,creative,marketing,web,automation,branding,logo,security,video,portfolio"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English"></meta>

        <meta
          property="og:title"
          content="Our Portfolio - Mozzo Creative Agency"
        />
        <meta property="og:site_name" content="Mozzo" />
        <meta property="og:url" content="https://mozzo.ch/portfolio" />
        <meta
          property="og:description"
          content="A Variety of Significant Projects that Have Been Successfully Delivered"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/images/ogimage.webp" />
      </Helmet>

      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeading
        title="A Variety of Significant Projects that Have Been Successfully Delivered"
        subTitle="Our Portfolio"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <Portfolio data={items} />
        <div className="cs_height_150 cs_height_lg_80" />
      </div>
    </HelmetProvider>
  );
}
