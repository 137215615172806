import React, { useEffect, useState } from "react";
import Spacing from "../Spacing";
import SectionHeading from "../SectionHeading";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helpers/PageTitle";
import Parser from "html-react-parser";
import axios from "axios";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ErrorPage from "./ErrorPage";

import { motion } from "framer-motion";

const API = "/data/portfolio.json";

export default function SinglePortfolio() {
  let { slug } = useParams();
  const [item, setItem] = useState([]);

  useEffect(() => {
    axios
      .get(API)
      .then((res) => {
        setItem(res.data.find((el) => el.slug === slug));
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  }, [slug]);

  pageTitle(item?.title || "");
  return !item ? (
    <ErrorPage />
  ) : (
    <HelmetProvider>
      <Helmet>
        <meta
          name="title"
          content={
            `${item.title} - Mozzo Creative Agency` || "Mozzo Creative Agency"
          }
        ></meta>
        <meta
          name="keywords"
          content="digital,agency,creative,marketing,web,automation,branding,logo,security,video,project"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English"></meta>

        <meta
          property="og:title"
          content={
            `${item.title} - Mozzo Creative Agency` || "Mozzo Creative Agency"
          }
        />
        <meta property="og:site_name" content="Mozzo" />
        <meta property="og:url" content="https://mozzo.ch/contact" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={item.mainImage} />
      </Helmet>

      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeading
        title={item?.title || ""}
        subTitle=""
        variant="text-center"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={{
              offscreen: {
                y: 50,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  bounce: 0.2,
                  duration: 0.6,
                  stiffness: 100,
                },
              },
            }}
            viewport={{
              once: true,
              amount: 0.2,
            }}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={item.mainImage} alt="Main Thumbnail" />
          </motion.div>
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            {/* <ul className="cs_portfolio_details_info cs_mp0">
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">Client:</h3>
                <p className="mb-0">Andrew romania</p>
              </li>
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">Location:</h3>
                <p className="mb-0">United Kindom</p>
              </li>
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">Budget:</h3>
                <p className="mb-0">$2500</p>
              </li>
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">
                  Project Completed:
                </h3>
                <p className="mb-0">11 August,2022</p>
              </li>
            </ul> */}
            <div>
              <h2 className="cs_fs_50 portfolio_subtitle">Project Brief</h2>
              {Parser(item.brief || "")}
            </div>
          </div>
          <Spacing lg="115" md="60" />
          <div className="cs_portfolio_details_gallery">
            {item?.video && (
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                variants={{
                  offscreen: {
                    y: 50,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      bounce: 0.2,
                      duration: 0.6,
                      stiffness: 100,
                    },
                  },
                }}
                viewport={{
                  once: true,
                  amount: 0.2,
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <video loop autoPlay muted>
                  <source src={item.video} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              </motion.div>
            )}
            {item.images?.map((img, index) => (
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                variants={{
                  offscreen: {
                    y: 50,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      bounce: 0.2,
                      duration: 0.6,
                      stiffness: 100,
                    },
                  },
                }}
                viewport={{
                  once: true,
                  amount: 0.2,
                }}
                key={index}
              >
                <img src={img.src} alt={img.alt} />
              </motion.div>
            ))}
          </div>
        </div>
        <Spacing lg="90" md="60" />
      </div>
      <Spacing lg="150" md="80" />
    </HelmetProvider>
  );
}
