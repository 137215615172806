import React from "react";
import parse from "html-react-parser";
import { motion } from "framer-motion";


export default function SectionHeading({
  title,
  subTitle,
  variantColor,
  variant,
}) {
  return (
    <div
      className={`cs_section_heading cs_style_1 ${
        variant ? variant : ""
      } text-center`}
    >
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              bounce: 0.4,
              duration: 0.4,
              stiffness: 40,
            },
          },
        }}
        viewport={{
          once: true,
          amount: 0.1,
        }}
      >
        <p className="cs_section_subtitle cs_accent_color cs_fs_18 mb-0">
          {parse(subTitle)}
        </p>
      </motion.div>
      <div className="cs_height_10 cs_height_lg_5" />
      {title !== "How we can help you?" ? (
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          variants={{
            offscreen: {
              y: 150,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.7,
                stiffness: 60,
              },
            },
          }}
          viewport={{
            once: true,
            amount: 0.2,
          }}
        >
          <h2
            className={`cs_section_title cs_fs_90 text_uppercase mb-0 ${
              variantColor ? variantColor : ""
            }`}
          >
            {parse(title)}
          </h2>
        </motion.div>
      ) : (
        <h2
          className={`cs_section_title cs_fs_90 text_uppercase mb-0 ${
            variantColor ? variantColor : ""
          }`}
        >
          {title.split("").map((char, i) => (
            <motion.span
              initial="offscreen"
              whileInView="onscreen"
              variants={{
                offscreen: {
                  opacity: 0,
                },
                onscreen: {
                  opacity: 1,
                  transition: {
                    duration: 0.1,
                    delay: i / 30,
                  },
                },
              }}
              viewport={{
                once: true,
                amount: 0.1,
              }}
              key={i}
            >
              {char}
            </motion.span>
          ))}
        </h2>
      )}
    </div>
  );
}
