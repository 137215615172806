import React from "react";
import Spacing from "../Spacing";
import TeamMember from "../TeamMember";
import SectionHeading from "../SectionHeading";
import { pageTitle } from "../../helpers/PageTitle";
import { Helmet, HelmetProvider } from "react-helmet-async";

const teamMembers = [
  {
    memberImg: "/images/team/blendi-600w.webp",
    memberName: "Blend Dubova",
    title: "Marketing Director",
    linkedin: "https://www.linkedin.com/in/blenddubova/",
  },
  {
    memberImg: "/images/team/mali-600w.webp",
    memberName: "Ermal Konjufca",
    title: "Web Developer",
    linkedin: "https://www.linkedin.com/in/ermalkonjufca/",
  },
  {
    memberImg: "/images/team/freska-600w.webp",
    memberName: "Freskim Shabani",
    title: "Graphic Designer",
    linkedin: "https://www.linkedin.com/in/freskim-shabani-549349275/",
  },
  {
    memberImg: "/images/team/emine-600w.webp",
    memberName: "Emine Salihaj",
    title: "Graphic Designer",
    linkedin: "https://www.linkedin.com/in/emine-salihaj-bb77a4268/",
  },
  {
    memberImg: "/images/team/kela-600w.webp",
    memberName: "Shkelqim Zeneli",
    title: "Graphic Designer",
    linkedin: "https://www.linkedin.com/in/shkelqim-zeneli-086192223/",
  },
];

export default function TeamPage() {
  pageTitle("Our Team");
  return (
    <HelmetProvider>
      <Helmet>
        <meta name="title" content="Our Team - Mozzo Creative Agency"></meta>
        <meta
          name="description"
          content="Our people are fully loaded with skill & ideas."
        />
        <meta
          name="keywords"
          content="digital,agency,creative,marketing,web,automation,branding,logo,security,video,team"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English"></meta>

        <meta property="og:title" content="Our Team - Mozzo Creative Agency" />
        <meta property="og:site_name" content="Mozzo" />
        <meta property="og:url" content="https://mozzo.ch/our-team" />
        <meta
          property="og:description"
          content="Our people are fully loaded with skill & ideas."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/images/ogimage.webp" />
      </Helmet>

      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      <SectionHeading title="Our Team" subTitle="Curious about us!" />

      <Spacing lg="75" md="60" />

      <section id="contentSection">
        <div id="teamCards">
          {teamMembers?.map((member, index) => (
            <TeamMember
              key={index}
              memberImg={member.memberImg}
              memberName={member.memberName}
              memberTitle={member.title}
              linkedin={member.linkedin}
            />
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
}
