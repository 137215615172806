import React, { useEffect, useState } from "react";
import Hero from "../Hero";
import Spacing from "../Spacing";
import ServiceStyle2 from "../Service/ServiceStyle2";
import Button from "../Button";
import SectionHeading from "../SectionHeading";
import { pageTitle } from "../../helpers/PageTitle";
import Marquee from "../Marquee";
import BrandsStyle2 from "../Brands/BrandsStyle2";
import { motion } from "framer-motion";
import axios from "axios";
import PostCarousel from "../Slider/PostCarousel";
import { Helmet, HelmetProvider } from "react-helmet-async";

const API = "/data/portfolio.json";

const serviceData = [
  {
    number: "01",
    title: "Digital",
    _title: "Marketing",
    subTitle:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium lorema doloremque laudantium, totam rem aperiam, eaque ipsa quae.",
    icon1: "/images/icons/like_icon.svg",
    icon2: "/images/icons/heartlike_icon.svg",
    href: "/service/service-details",
  },
  {
    number: "02",
    title: "Graphic",
    _title: "Design",
    subTitle:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium lorema doloremque laudantium, totam rem aperiam, eaque ipsa quae.",
    icon1: "/images/icons/pencil_icon.svg",
    icon2: "/images/icons/image_icon.svg",
    href: "/service/service-details",
  },
  {
    number: "03",
    title: "Web",
    _title: "Development",
    subTitle:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium lorema doloremque laudantium, totam rem aperiam, eaque ipsa quae.",
    icon1: "/images/icons/code_icon.svg",
    icon2: "/images/icons/web_icon.svg",
    href: "/service/service-details",
  },
  {
    number: "04",
    title: "Cyber",
    _title: "Security",
    subTitle:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium lorema doloremque laudantium, totam rem aperiam, eaque ipsa quae.",
    icon1: "/images/icons/locker_icon.svg",
    icon2: "/images/icons/check_icon.svg",
    href: "/service/service-details",
  },
  {
    number: "05",
    title: "Video",
    _title: "Content",
    subTitle:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium lorema doloremque laudantium, totam rem aperiam, eaque ipsa quae.",
    icon1: "/images/icons/video_icon.svg",
    icon2: "/images/icons/play_icon.svg",
    href: "/service/service-details",
  },
];

const brandData = [
  {
    logoSrc: "/images/partners/aogastro.svg",
    logoAlt: "AO Gastro",
    href: "https://ao-gastro.ch",
  },
  {
    logoSrc: "/images/partners/elmariachi.svg",
    logoAlt: "El Mariachi - Restaurant & Pizzeria",
    href: "https://elmariachi-restaurant.ch/",
  },
  {
    logoSrc: "/images/partners/giardino.svg",
    logoAlt: "Giardino Steakhouse",
    href: "https://giardino-sursee.ch/",
  },
  {
    logoSrc: "/images/partners/kuonimatt.svg",
    logoAlt: "Kuonimatt Restaurant & Pizzeria",
    href: "https://restaurant-kuonimatt.ch/",
  },
  {
    logoSrc: "/images/partners/latorreta.svg",
    logoAlt: "La Torreta",
    href: "https://latorreta.ch/",
  },
  {
    logoSrc: "/images/partners/lux.svg",
    logoAlt: "LUX - Restaurant & Pizzeria",
    href: "https://restaurant-pizzeria-lux.ch/",
  },
  {
    logoSrc: "/images/partners/migalu.svg",
    logoAlt: "Militaergarten",
    href: "https://xn--militrgarten-kcb.ch/",
  },
  {
    logoSrc: "/images/partners/papazziano.svg",
    logoAlt: "Papazziano - Burger & Pizza & Pasta",
    href: "https://papazziano.ch/",
  },
  {
    logoSrc: "/images/partners/navinaimmo.svg",
    logoAlt: "Navima Immo AG",
    href: "https://navinaimmo.ch/",
  },
  {
    logoSrc: "/images/partners/tuermli.svg",
    logoAlt: "Tuermli",
    href: "https://tuermli-sempach.ch/",
  },
  {
    logoSrc: "/images/partners/zellfeld.svg",
    logoAlt: "Restaurant & Pizzeria Zellfeld",
    href: "https://restaurant-zellfeld.ch/",
  },
  {
    logoSrc: "/images/partners/mrukko.svg",
    logoAlt: "Mr Ukko Underwear",
    href: "#",
  },
  {
    logoSrc: "/images/partners/babylon.svg",
    logoAlt: "Babylon",
    href: "#",
  },
  {
    logoSrc: "/images/partners/wystube.svg",
    logoAlt: "Wystube Vinothek",
    href: "https://wystube-vinothek.ch/",
  },
  {
    logoSrc: "/images/partners/driloni.svg",
    logoAlt: "Driloni Sportswear",
    href: "https://driloni-ks.com/",
  },
  {
    logoSrc: "/images/partners/daffodills.svg",
    logoAlt: "Daffodills",
    href: "#",
  },
  {
    logoSrc: "/images/partners/bueli.svg",
    logoAlt: "Bueli Pflege",
    href: "#",
  },
  {
    logoSrc: "/images/partners/unico.svg",
    logoAlt: "Unico",
    href: "https://unico-rental.be",
  },
];

export default function Home() {
  pageTitle("Home");

  const [featuredPortfolio, setFeaturedPortfolio] = useState([]);
  useEffect(() => {
    axios
      .get(API)
      .then((res) => {
        setFeaturedPortfolio(res.data.slice(0, 5));
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Mozzo - Creative Agency</title>
        <meta name="title" content="Mozzo - Creative Agency"></meta>
        <meta
          name="description"
          content="Transform visions into unforgettable digital experiences. | +383 44 690213 · info@mozzo.ch · Rr. Deshmoret e Kombit PN, Ferizaj, Republic of Kosovo"
        />
        <meta
          name="keywords"
          content="digital,agency,creative,marketing,web,automation,branding,logo,security,video"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English"></meta>

        <meta property="og:title" content="Mozzo - Creative Agency" />
        <meta property="og:site_name" content="Mozzo" />
        <meta property="og:url" content="https://mozzo.ch/" />
        <meta
          property="og:description"
          content="Transform visions into unforgettable digital experiences. | +383 44 690213 · info@mozzo.ch · Rr. Deshmoret e Kombit PN, Ferizaj, Republic of Kosovo"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/images/ogimage.webp" />
      </Helmet>

      <Hero
        title={["Advertising made simple", "Outperform the competition"]}
        subtitle="USER-CENTRIC EXPERIENCES, SEAMLESS INTERACTIONS"
        // videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
        bgUrl="/images/banner.webp"
      />
      <Spacing lg="100" md="70" />
      <Marquee text="Logo - Brand Identity - Marketing - SEO - Web Development - Web Automation - Video -" />
      <Spacing lg="100" md="70" />

      <section>
        <Spacing lg="150" md="70" />
        <div className="container" id="services">
          <SectionHeading
            title="What we offer"
            subTitle="Services"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <ServiceStyle2 variantColor="cs_color_1" data={serviceData} />
        </div>
        <Spacing lg="145" md="80" />
      </section>
      

      <section>
        <Spacing lg="118" md="70" />
        <div className="container">
          <SectionHeading title="Latest and greatest" subTitle="Portfolio" />
          <Spacing lg="70" md="35" />
        </div>

        <motion.div
          className="portfolio_carousel"
          initial="offscreen"
          whileInView="onscreen"
          variants={{
            offscreen: {
              y: 200,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                type: "spring",
                duration: 0.8,
                stiffness: 50,
              },
            },
          }}
          viewport={{
            once: true,
            amount: 0.1,
          }}
        >
          <PostCarousel data={featuredPortfolio} />
        </motion.div>
        <Spacing lg="70" md="35" />

        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          variants={{
            offscreen: {
              y: 50,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                type: "spring",
                bounce: 0.2,
                duration: 0.6,
                stiffness: 100,
              },
            },
          }}
          viewport={{
            once: true,
            amount: 0.2,
          }}
        >
          <div className="container">
            <div className="text-center">
              <Button btnText="See more" btnUrl="/portfolio" />
            </div>
          </div>
        </motion.div>
        <Spacing lg="100" md="50" />
      </section>

   

      <Spacing lg="145" md="80" />
      <div className="container" id="our_partners">
        <SectionHeading title="Our Trusted Clients" subTitle="Our partners" />
        <Spacing lg="85" md="45" />
        <BrandsStyle2 data={brandData} />
      </div>
      <div className="cs_height_200 cs_height_lg_100"></div>

      {/* <TestimonialSlider
        layeredImages={[
          '/images/creative-agency/layer_img_1.jpeg',
          '/images/creative-agency/layer_img_2.jpeg',
          '/images/creative-agency/layer_img_3.jpeg',
          '/images/creative-agency/layer_img_4.jpeg',
          '/images/creative-agency/layer_img_5.jpeg',
        ]}
        data={testimonialData}
      />
       */}
      {/*       
      <section>
        <div className="container">
          <Cta
            title="Is there a specific project or goal that you have in mind?"
            btnText="Contact Us"
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section> */}

      {/*       
      <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Some recent news" subTitle="Our Blog" />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
      </section> */}

      {/* <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section> */}
    </HelmetProvider>
  );
}
